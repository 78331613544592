var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "uk-visible@l",
      on: {
        mouseleave: function ($event) {
          return _vm.closeAllMegaMenus()
        },
      },
    },
    [
      _c("div", { staticClass: "uk-container c-main-navbar__wrapper" }, [
        _c("div", { staticClass: "c-main-navbar" }, [
          _c("div", { staticClass: "c-main-navbar__left" }, [
            _c(
              "a",
              { staticClass: "uk-logo", attrs: { href: _vm.routes.home } },
              [
                _c("img", {
                  staticClass: "lazyload",
                  attrs: {
                    "data-src": "/dist/assets/images/logo.svg",
                    width: "230",
                    draggable: "false",
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-main-navbar__right" }, [
            _c(
              "nav",
              {
                staticClass: "c-main-navbar__top",
                on: {
                  mouseover: function ($event) {
                    return _vm.closeAllMegaMenus()
                  },
                },
              },
              [
                _c(
                  "ul",
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "has-icon",
                          attrs: { href: _vm.routes.catalogi },
                        },
                        [_vm._m(0), _vm._v(_vm._s(_vm.translations.catalogi))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "has-icon",
                          attrs: { href: _vm.routes.wishlist },
                        },
                        [_vm._m(1), _vm._v(_vm._s(_vm.translations.wishlist))]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.secondaryMenu, function (sec_mi) {
                      return _c("li", { key: sec_mi.id }, [
                        _c("a", { attrs: { href: sec_mi.slug } }, [
                          _vm._v(_vm._s(sec_mi.title)),
                        ]),
                      ])
                    }),
                    _vm._v(" "),
                    _vm.languages.length > 1
                      ? _c("li", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(_vm.activeLanguage.shorthand) + " "),
                            _c("i", {
                              staticClass: "fa-solid fa-caret-down ms-1",
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "c-main-navbar__dropdown",
                              attrs: { "uk-dropdown": "" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "uk-nav uk-dropdown-nav" },
                                _vm._l(_vm.languages, function (language) {
                                  return _c(
                                    "li",
                                    {
                                      class: {
                                        "is-active":
                                          language.shorthand ==
                                          _vm.activeLanguage.shorthand,
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        { attrs: { href: language.slug } },
                                        [_vm._v(_vm._s(language.shorthand))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _c("nav", { staticClass: "c-main-navbar__bttm" }, [
              _c(
                "ul",
                _vm._l(_vm.primaryMenu, function (prim_mi) {
                  return _c("li", { key: prim_mi.id }, [
                    prim_mi.id == 1410
                      ? _c(
                          "a",
                          {
                            class: {
                              "is-active": prim_mi.id == _vm.rootPageId,
                            },
                            attrs: { href: prim_mi.slug },
                            on: {
                              mouseover: function ($event) {
                                return _vm.openMegaMenu("1410")
                              },
                            },
                          },
                          [_vm._v(_vm._s(prim_mi.title))]
                        )
                      : prim_mi.id == 3532
                      ? _c(
                          "a",
                          {
                            class: {
                              "is-active": prim_mi.id == _vm.rootPageId,
                            },
                            attrs: { href: prim_mi.slug },
                            on: {
                              mouseover: function ($event) {
                                return _vm.openMegaMenu("3532")
                              },
                            },
                          },
                          [_vm._v(_vm._s(prim_mi.title))]
                        )
                      : _c(
                          "a",
                          {
                            class: {
                              "is-active": prim_mi.id == _vm.rootPageId,
                            },
                            attrs: { href: prim_mi.slug },
                            on: {
                              mouseover: function ($event) {
                                return _vm.closeAllMegaMenus()
                              },
                            },
                          },
                          [_vm._v(_vm._s(prim_mi.title))]
                        ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "h-p-rel" }, [
        _c(
          "div",
          { ref: "megaMenuCat", staticClass: "c-main-navbar__megadropdown" },
          [
            _c("div", { staticClass: "uk-container" }, [
              _c(
                "div",
                { staticClass: "c-category-grid mt-0" },
                [
                  _vm._l(_vm.categories, function (category) {
                    return category.childrenWithProducts.length > 0
                      ? _c(
                          "div",
                          {
                            key: category.id,
                            staticClass: "c-category-grid__section",
                            staticStyle: { "min-width": "33%" },
                          },
                          [
                            _c("h3", { staticClass: "t-h3 mt-0 mb-4" }, [
                              _vm._v(_vm._s(category.name)),
                            ]),
                            _vm._v(" "),
                            category.childrenWithProducts.length > 0
                              ? _c(
                                  "nav",
                                  {
                                    staticClass:
                                      "mb-4 c-list-nav c-list-nav--2",
                                    class: {
                                      "c-list-nav--2":
                                        category.childrenWithProducts.length >
                                        8,
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        category.childrenWithProducts,
                                        function (category_niv2) {
                                          return _c(
                                            "li",
                                            { key: category_niv2.id },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "c-button-link-nav",
                                                      attrs: {
                                                        href: category_niv2.slug,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          category_niv2.name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.categories, function (category) {
                    return category.childrenWithProducts.length == 0
                      ? _c(
                          "div",
                          {
                            key: category.id,
                            staticClass: "c-category-grid__section",
                            staticStyle: { "min-width": "33%" },
                          },
                          [
                            _c("h3", { staticClass: "t-h3 mt-0 mb-4" }, [
                              _vm._v(_vm._s(category.name)),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                ],
                2
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { ref: "megaMenuProf", staticClass: "c-main-navbar__megadropdown" },
          [
            _c("div", { staticClass: "uk-container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-child-width-1-4 uk-child-width-1-5@l uk-grid-small",
                  attrs: { "uk-grid": "" },
                },
                _vm._l(_vm.professionals, function (professional) {
                  return _c("div", { key: professional.id }, [
                    _c(
                      "a",
                      {
                        staticClass: "c-big-link-category",
                        attrs: { href: professional.slug },
                      },
                      [
                        _c("img", {
                          staticClass: "c-big-link-category__image",
                          attrs: { src: professional.img },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "c-big-link-category__label" },
                          [_vm._v(_vm._s(professional.title))]
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa-regular fa-arrow-down-to-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fa-regular fa-plus" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }