<template>        
    <div class="uk-visible@l"  @mouseleave="closeAllMegaMenus()">
        <div class="uk-container c-main-navbar__wrapper">
            <div class="c-main-navbar">
                <div class="c-main-navbar__left">
                    <a :href="routes.home" class="uk-logo">
                        <img class="lazyload" data-src="/dist/assets/images/logo.svg" width="230" draggable="false">
                    </a>
                </div>

                <div class="c-main-navbar__right">
                    <nav class="c-main-navbar__top" @mouseover="closeAllMegaMenus()">
                        <ul>
                            <li><a :href="routes.catalogi" class="has-icon"><span><i class="fa-regular fa-arrow-down-to-line"></i></span>{{translations.catalogi}}</a></li>
                            <li><a :href="routes.wishlist" class="has-icon"><span><i class="fa-regular fa-plus"></i></span>{{translations.wishlist}}</a></li>
                            
                            <!-- sec nav -->
                            <li v-for="sec_mi in secondaryMenu" :key="sec_mi.id"><a :href="sec_mi.slug">{{sec_mi.title}}</a></li>

                            <!-- languages -->
                            <li v-if="languages.length > 1">
                                <a href="#">{{activeLanguage.shorthand}} <i class="fa-solid fa-caret-down ms-1"></i></a>
                                <div class="c-main-navbar__dropdown" uk-dropdown>
                                    <ul class="uk-nav uk-dropdown-nav">
                                        <li v-for="language in languages" :class="{'is-active' : language.shorthand == activeLanguage.shorthand}"><a :href='language.slug'>{{language.shorthand}}</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </nav>

                    <nav class="c-main-navbar__bttm">
                        <!-- primary nav -->
                        <ul>
                            <li v-for="prim_mi in primaryMenu" :key="prim_mi.id">
                                <a v-if="prim_mi.id == 1410" :href="prim_mi.slug" @mouseover="openMegaMenu('1410')"  :class="{'is-active' : prim_mi.id == rootPageId}">{{prim_mi.title}}</a> <!-- products -->
                                <a v-else-if="prim_mi.id == 3532" :href="prim_mi.slug" @mouseover="openMegaMenu('3532')" :class="{'is-active' : prim_mi.id == rootPageId}">{{prim_mi.title}}</a> <!-- professionals -->
                                <a v-else :href="prim_mi.slug" @mouseover="closeAllMegaMenus()" :class="{'is-active' : prim_mi.id == rootPageId}">{{prim_mi.title}}</a> <!-- normal -->
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="h-p-rel">
            <div class="c-main-navbar__megadropdown" ref="megaMenuCat">
                <div class="uk-container">
                    
                    <div class="c-category-grid mt-0">
                        <div v-if="category.childrenWithProducts.length > 0" v-for="category in categories" :key="category.id" class="c-category-grid__section" style="min-width: 33%;">
                            <h3  class="t-h3 mt-0 mb-4">{{category.name}}</h3>

                            <nav v-if="category.childrenWithProducts.length > 0" class="mb-4 c-list-nav c-list-nav--2" :class="{'c-list-nav--2' : category.childrenWithProducts.length > 8}">
                                <ul>
                                    <li v-for="category_niv2 in category.childrenWithProducts" :key="category_niv2.id"><div class="d-flex"><a :href="category_niv2.slug" class="c-button-link-nav">{{category_niv2.name}}</a></div></li>
                                </ul>
                            </nav>
                        </div>
                        <div v-if="category.childrenWithProducts.length == 0" v-for="category in categories" :key="category.id" class="c-category-grid__section" style="min-width: 33%;">
                            <h3 class="t-h3 mt-0 mb-4">{{category.name}}</h3>
                        </div>
                    </div>

                </div>
            </div>
            <div class="c-main-navbar__megadropdown" ref="megaMenuProf">
                <div class="uk-container">
                    
                    <div class="uk-child-width-1-4 uk-child-width-1-5@l uk-grid-small" uk-grid>
                        <div v-for="professional in professionals" :key="professional.id">
                            <a :href="professional.slug" class="c-big-link-category">
                                <img :src='professional.img' 
                                    class="c-big-link-category__image">

                                <span class="c-big-link-category__label">{{professional.title}}</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: "MainNavbar",        
        mounted() {
         
        },
        data() {
            return {
            };
        },
        methods: {
            openMegaMenu(id) {
                if(id == 1410) {
                    this.$refs.megaMenuCat.classList.add('is-shown');
                } else if(id == 3532) {
                    this.$refs.megaMenuProf.classList.add('is-shown');
                }
            },
            closeAllMegaMenus() {
                this.$refs.megaMenuCat.classList.remove('is-shown');
                this.$refs.megaMenuProf.classList.remove('is-shown');
            },
        },
        props: {
            translations: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
            routes: {
                type: Object,
                required: true
            },
            languages: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
            activeLanguage: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
            currentPageId: {
                type: String|Number,
                required: true
            },
            rootPageId: {
                type: String|Number,
                required: true
            },
            secondaryMenu: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
            primaryMenu: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
            categories: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
            professionals: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
        }
    }
</script>

<style scoped>

</style>